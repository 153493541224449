import Vue from 'vue'
import axios from 'axios'
import Router, { RawLocation } from 'vue-router'
import store from '@store'
import middlewarePipeline from './middleware-pipeline'
import routesErrors from './routes-errors'
import routesRedirects from './routes-redirects'
import routesModules from './routes-modules'

Vue.use(Router)

const router = new Router({
    linkActiveClass: 'has-active',
    linkExactActiveClass: 'active',
    routes: [
        {
            path: '/',
            redirect(): RawLocation {
                if (store.getters['Auth/isAuthenticated'])
                    return { name: 'dashboard' }

                return { name: 'auth.signin' }
            },
        },
        ...routesModules,
        ...routesErrors,
        ...routesRedirects,
    ],
})

router.beforeEach((to, from, next) => middlewarePipeline(to, from, next, store))

router.afterEach(() => {
    window.Intercom("update")
})

router.onError((error) => {
    if (axios.isAxiosError(error)) {
        const redirects = [401, 403, 404]

        if (error.response?.status && redirects.includes(error.response.status)) {
            router.replace({ name: String(error.response.status) })
        }
    }

    throw error
})


export default router
