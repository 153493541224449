import moment from 'moment'
import { buildFullPath } from '@common/Resources/utils'

export const initIntercom =  (user: UserModel, userType: UserType, organization: AppConfig ): void => {

    // comment below if you want to debug
    if (NODE_ENV === 'development') {
        return
    }

    if (organization.id === 636 || userType !== 'admin'  )
        return

    window.Intercom("boot", {
        api_base: "https://api-iam.eu.intercom.io",
        app_id: "uv2uijtr",
        user_id: user.id.toString(),
        name: user.profile.firstname + ' ' + user.profile.lastname,
        email: user.email,
        created_at: moment(user.timestamp).unix(),
        custom_launcher_selector: '.intercom-trigger',
        company: {
            name: organization.name,
            id: organization.id.toString(),
            "Organization ID": organization.id.toString(),
        },
        avatar: {
            type: 'avatar',
            image_url: buildFullPath(user.image_path),
        },
        
      });      
}

